import { css } from '@emotion/react';
import React, { Fragment, memo } from 'react';
import { UserAvatarAsDialogButton } from '~/modules/auth/components/UserAvatarAsDialogButton';
import { useSlack } from '~/modules/SDK/slack/useSlack';
import { useTimeout } from 'react-use';
import { flex } from '~/modules/AppLayout/FlexGridCss';
import { fontWeight600 } from '~/css/font';
import { useMeStore } from '~/modules/SDK/me/useMeStore';
export const NoSubscriptView = memo(function NoSubscriptView() {
    const [isReady, cancel] = useTimeout(3000);
    const slack = useSlack();
    if (isReady())
        slack.postMessage('試圖登入使用，但權限未開通');
    const { meUserState } = useMeStore();
    const userCode = meUserState?.code?.toString();
    const userInfo = (<UserAvatarAsDialogButton rootCSS={css `
        padding: 35px 35px;
        border-radius: 10px;
      `}/>);
    return (<Fragment>
      <div css={css `
          ${flex.h.allCenter};
          background-color: #ffffff;
          border-radius: 5px;
          padding: 20px;
          border: 1px solid #555555;
          gap: 16px;
        `}>
        <div css={css `
            background-color: #ffffff;
            font-size: 18px;
            height: 70px;
            ${fontWeight600};
          `}>
          <div css={css `
              ${flex.v.allCenter};
              height: 35px;
              color: #cc0000;
              border-radius: 6px;
            `}>
            付費會員功能，請洽客服人員開通。
          </div>
          <div css={css `
              ${flex.v.allCenter};
              height: 35px;
              color: #ffffff;
              background-color: #aa0000;
              border-radius: 6px;
            `}>
            會員代碼 : {userCode}
          </div>
        </div>
        <div css={css `
            height: 70px;
            ${flex.v.allCenter};
            border-radius: 6px;
            background-color: #eeeeee;
          `}>
          {userInfo}
        </div>
      </div>
    </Fragment>);
});
